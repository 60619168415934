// eslint-disable-next-line no-unused-vars
import { Card, Table,Grid } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { env } from "../../configs/EnvironmentConfig";
import { General_Constants } from "../../constants/GeneralConstant";
import Utils from "../../utils";
import api from "../../configs/api";


const Stat = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [todaymatches, settodayMatches] = useState([]);
  const [pastmatches, setpastMatches] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [upcomingmatches, setupcomingMatches] = useState([]);
  const { useBreakpoint } = Grid;
  
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const getDateFormat = (props, flag) => {
    const dFormat = "MMM DD, YYYY";
    if (typeof props === 'object') {
      const { date, format = dFormat, isTime } = props || {}
      if (!date) return;
      if (isTime) return dayjs(date).format(`${format} hh:mm a`)
      return dayjs(date).format(format)
    } else {
      if (flag) return props ? dayjs(props) : dayjs();
      return props ? dayjs(props).format(`${dFormat}`) : dayjs();
    }
  }
  const scoreColumns = [
    {
      title: (
        <span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>
          Date
        </span>
      ),
      fixed: 'left',
      dataIndex: "date",
      key: "date",
      width:isMobile?undefined:'30px',
      render: (text) => {return (<><span>{getDateFormat({ date: text, format: 'MMM DD, YYYY' })}</span>{text && <span></span>}</>)}// - {dayjs(text).add(45,'minutes').format('hh:mm a')}
    },
    {
      title: (
        <span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>
          Time
        </span>
      ),
      //fixed: 'left',
      dataIndex: "date",
      key: "date",
      width:isMobile?undefined:'20px',
      render: (text) => {return (<><span>{getDateFormat({ date: text, format: 'hh:mm a' })}</span>{text && <span></span>}</>)}// - {dayjs(text).add(45,'minutes').format('hh:mm a')}
    },
    {
      title:(<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.HOME}</span>),
      children:[{
      title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.TEAM}</span>),
      dataIndex: "team",
      key: "team",
      // filterSearch: true,
      // filters: [],
      width: isMobile?undefined:"30px",
      render: (text, gl) => {
        return (
            <span>{gl?.home?.team?.name}</span>
        )
      }
    },
    {
      title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.TIER}</span>),
      dataIndex: "tier",
      key: "tier",
      // filterSearch: true,
      // filters: [],
        filterSearch: false,
      width: isMobile?undefined:"20px",
      render: (text, gl) => {
        return (
          <>
            <span>{gl?.home?.tier==='1'?'East':'West'}</span>
          </>
        )
      }
    }],
  },
    {title:(<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.AWAY}</span>),
    children:[{
    title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.TEAM}</span>),
    dataIndex: "team",
    key: "team",
    filterSearch: false,
    // filterSearch: true,
    // filters: [],
    width: "30px",
    render: (text, gl) => {
      return (
        <>
          <span>{gl?.away?.team?.name}</span>
        </>
      )
    }
  },
    {
      title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.TIER}</span>),
      dataIndex: "tier",
      key: "tier",
      // filterSearch: true,
      // filters: [],
      width: "20px",
      render: (text, gl) => {
        return (
          <>
            <span>{((process.env.REACT_APP_DEV_API_REDIRECT_URL).includes('tcbl'))?gl?.away?.tier:gl?.away?.tier==='1'?'East':'West'}</span>
          </>
        )
      }
    },]},
    // {
    //   title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>{General_Constants.SEASON}</span>),
    //   dataIndex: "season",
    //   key: "season",
    //   // filters: [],
    //   // filterSearch: true,
    //   width: "30px",
    //   render: (text, record) => record?.sports_event?.name
    // }, 
    // {
    //   title: (<span style={isMobile?{ color: "#000000", fontSize: "12px", fontWeight: "500" }:{ color: "#000000", fontSize: "18px", fontWeight: "700" }}>League</span>),
    //   dataIndex: "league",
    //   key: "league",
    //   // filterSearch: true,
    //   // filters: [],
    //   width: "30px",
    //   render: (text, record) => record?.league?.name
    // }, 
  ];

  // eslint-disable-next-line no-unused-vars
  const dayspliter = (matches) => {
    const matchlist = [];
    matchlist[0] = [matches[0]];
    let j = 0;
    for (let i = 1; i < matches.length; i++) {
      if (
        dayjs(matches[i]?.date).format("YYYY-MM-DD") ===
        dayjs(matchlist[j]?.[0]?.date).format("YYYY-MM-DD")
      ) {
        matchlist[j].push(matches[i]);
      } else {
        matchlist[j + 1] = [matches[i]];
        j += 1;
      }
    }
    return matchlist;
  };
  const loadMatches = async (court = []) => {
    const courts = {};
    const courtsarray = [];
    const toDate = dayjs().add(2, "years").format("YYYY-MM-DD");
    const fromDate = dayjs().add(-2, "days").format("YYYY-MM-DD");
    let query = `?from=${fromDate}&to=${toDate}&limit=100`;//
    console.log(props.leaguesList,props?.league,'props?.league')
    console.log(props,'props')
    if(props?.season) {
      query+=`&season=${props.season}`
    }
    const league = props?.leaguesList?.find((item)=>item?.name ===props?.league)?._id
    if(league) {
      query+=`&league=${league}`
    }
    // if(props.tier) {
    //   query+=`&tier=${props.tier}`
    // }
    const { data } = await api.get(
      `${env?.API_ENDPOINT_URL}/settings/schedules${query}`
    );
    // eslint-disable-next-line no-unused-vars
    let v1 = data?.results?.forEach((item) => {
      if (!courts[item?.court?._id]) {
        courts[item?.court?._id] = item?.court?.name;
        courtsarray.push(item?.court);
      }
    });
    const todaymatch = data?.results?.filter((item) => {
      return (
        dayjs(item?.date).format("YYYY-MM-DD") ===
          dayjs().format("YYYY-MM-DD") &&
        (court.length ? court : courtsarray?.[0]?._id) === item?.court?._id
      );
    });
    const recentmatch = dayspliter(data?.results?.filter((item)=>{
      return dayjs(item?.date) <= dayjs() && ((court.length)?court:courtsarray?.[0]?._id) === item?.court?._id
    }).sort(function(a, b){return new Date(a.date) - new Date(b.date)})?.map(ee => ({ ...ee, key: ee._id })))
    const upcomingmatch = dayspliter(data?.results?.filter((item)=>{
      return dayjs(item?.date) >= dayjs() && ((court.length)?court:courtsarray?.[0]?._id) === item?.court?._id
    }).sort(function(a, b){return new Date(a.date) - new Date(b.date)})?.map(ee => ({ ...ee, key: ee._id })))
    const todaymatchlist =
      todaymatch?.map((ee) => ({ ...ee, key: ee._id })) ?? [];
    settodayMatches([...todaymatchlist]);
    console.log(props.tier,'props.tier')
    setpastMatches([...[...upcomingmatch].flat().filter(item=> item?.league?.name === props.league && item?.sports_event?._id === props.season && (item?.home?.tier === props.tier || props.tier ==='All' || !Boolean(props.tier)))])
    setupcomingMatches()
    console.log(todaymatchlist,'todaymatchlist',recentmatch,upcomingmatch,props.season,[...[...upcomingmatch].flat().filter(item=> item?.league?.name === props.league)])
  };
  useEffect(() => {
    loadMatches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.season,props.league,props.tier]);
  return (
    <>
      <div style={{ minHeight: "300px", backgroundColor: "white" }}>
        <Table columns={scoreColumns} dataSource={Boolean(pastmatches?.[0])?pastmatches:[]} scroll={isMobile?undefined:{
                y: 400,
              }}/>
      </div>
    </>
  );
};
export default Stat;
