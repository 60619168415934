/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Menu,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Grid,
  Button,
  Modal,
  Tooltip,
} from "antd";
import { env } from "../configs/EnvironmentConfig";
//import Leagues from "./leagues"
import Schedule from "./schedules";
import Leaders from "./leaders";
import TeamStats from "./teamstats";
import Filter from "./filter";
import dayjs from "dayjs";
import { General_Constants } from "../constants/GeneralConstant";
import basktetball from "../assets/images/basketball-jersey.png";
import rankicon from "../assets/images/rank.svg";
import ApiConfig from "../configs/ApiConfig";
//import statsComp from "./stats";
import styles from "./stats.module.scss";
import Utils from "../utils";
import PlayerPlaceHolderImg from "./player_placeholer.png";
import AlternateTeamsNameLogo from "./AlternateTeamsNameLogo";
import api from "../configs/api";

const Stats = () => {
  const [loading, setloading] = useState(true);
  const { useBreakpoint } = Grid;
  const [leaderstype, setleaderstype] = useState("pts");

  const [detailType, setdetailType] = useState("1");
  const [current, setCurrent] = useState(
    process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
      ? "standings"
      : "stats"
  );

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const itemsStyle = isMobile
    ? {
        fontSize: "14px",
        fontWeight: "500",
        color: "#000",
        display: "inline-block",
      }
    : {
        fontSize: "20px",
        fontWeight: "700",
        color: "#000",
        marginRight: "25px",
        display: "inline-block",
      };
  const standingsColumnsTitleStyle = isMobile
    ? { color: "#000000", fontSize: "12px", fontWeight: "500" }
    : { color: "#000000", fontSize: "18px", fontWeight: "700" };

  const [settings, setsettings] = useState({});
  const getaccountsettings = async () => {
    try {
      const response = await api.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      const v1 = {};
      response?.data?.[0]?.divisions?.forEach((item) => {
        v1[item.divisionid] = item.label;
      });
      const v2 = {};
      response?.data?.[0]?.leagues?.forEach((item) => {
        v1[item.leagueid] = item.label;
      });
      setsettings({
        leagues: { ...v2 },
        divisions: { ...v1 },
        logo: response?.data?.[0]?.logo,
      });
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    getaccountsettings();
  }, []);
  const items = [
    {
      label: (
        <span
          style={
            current === "stats"
              ? {
                  ...itemsStyle,
                  fontSize: "25px",
                  opacity: "0.8",
                  color: "#3e79f7",
                }
              : itemsStyle
          }
        >
          {General_Constants.SCORES}
        </span>
      ),
      key: "stats",
    },
    {
      label: (
        <span
          style={
            current === "leaders"
              ? {
                  ...itemsStyle,
                  fontSize: "25px",
                  opacity: "0.8",
                  color: "#3e79f7",
                }
              : itemsStyle
          }
        >
          {General_Constants.PLAYERS}
        </span>
      ),
      key: "leaders",
      //disabled: true,
    },
    {
      label: (
        <span
          style={
            current === "teamstats"
              ? {
                  ...itemsStyle,
                  fontSize: "25px",
                  opacity: "0.8",
                  color: "#3e79f7",
                }
              : itemsStyle
          }
        >
          {General_Constants.TEAMSTATS}
        </span>
      ),
      key: "teamstats",
      //disabled: true,
    },
    // {
    //   label: <span style={current==='playoffs'?{...itemsStyle,fontSize:'25px',opacity:'0.8',color:'#3e79f7'}:itemsStyle}>{General_Constants.PLAYOFFS}</span>,
    //   key: "playoffs",
    //   disabled: true,
    // },
  ];
  //const standingsColumnsStyles = isMobile?{fixed: 'left'}:{fixed: 'left',width:'100px'}
  const standingsColumns = [
    {
      title: (
        <span className={isMobile ? styles.standingcolumn : styles.columns}>
          {General_Constants.TEAM}
        </span>
      ),
      fixed: "left",
      width: isMobile ? "150px" : "50%",
      render: (record, text, k) => (
        <div className={styles.stats}>
          <div
            className="text-left"
            style={{ height: "16px", fontSize: isMobile ? "12px" : "16px" }}
          >
            <span
              style={
                isMobile
                  ? {
                      marginRight: "10px",
                      color: "#000000",
                      fontWeight: "500",
                      fontSize: "12px",
                    }
                  : { marginRight: "15px", color: "#000000", fontWeight: "500" }
              }
            >
              {k + 1}
            </span>{" "}
            {isMobile ? null : (
              <img
                src={
                  Boolean(record?.logo)
                    ? record?.logo
                    : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? "https://tcbl.ca/logos/logo.png"
                    : "https://tcbl2.poindexters.ca/img/loc.jpg"
                }
                alt=""
                style={{
                  height: "16px",
                  marginRight: "7px",
                  fontSize: "14px",
                  width: "16px",
                }}
              />
            )}{" "}
            <span
              style={{
                color: "red",
                fontWeight: "500",
                fontSize: isMobile ? "12px" : "12px",
              }}
            >
              {record?.name}
            </span>
            {/* <TeamsLogo name={record?.name} logo={record?.logo} /> */}
          </div>
        </div>
      ),
    },
    // { title: "Team", key: "name", width: "180px", dataIndex: "name" },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.GP}</span>
      ),
      width: isMobile ? "50px" : undefined,
      //width: "35px",
      key: "GP",
      dataIndex: "gp",
      render: (text, record) => (
        <div
          style={{
            textAlign: "left",
            fontWeight: "500",
            color: "#000000",
            fontSize: isMobile ? "12px" : undefined,
          }}
        >
          {parseInt(record?.win ?? 0) + parseInt(record?.loss ?? 0)}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.W}</span>
      ),
      width: isMobile ? "35px" : undefined,
      key: "win",
      dataIndex: "win",
      render: (text) => (
        <div
          style={{
            textAlign: "left",
            fontWeight: "500",
            color: "#000000",
            fontSize: isMobile ? "12px" : undefined,
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.L}</span>
      ),
      //width: "35px",
      width: isMobile ? "35px" : undefined,
      key: "loss",
      dataIndex: "loss",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.PCT}</span>
      ),
      width: isMobile ? "70px" : undefined,
      //width: "35px",
      key: "pct",
      dataIndex: "pct",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
    // { title: "STRK", key: "streak", dataIndex: "streak" },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.PF}</span>
      ),
      width: isMobile ? "60px" : undefined,
      //width: "35px",
      key: "pf",
      dataIndex: "pf",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.PA}</span>
      ),
      width: isMobile ? "60px" : undefined,
      //width: "35px",
      key: "pa",
      dataIndex: "pa",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.DIFF}</span>
      ),
      width: isMobile ? "80px" : undefined,
      //width: "35px",
      key: "diff",
      dataIndex: "diff",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
  ];

  const [teamsInfo, setteamsInfo] = useState([]);

  const teamsNameLogo = (values) => {
    const won = values?.teams?.[0]?.team === values?.won_by?.team ? 0 : 1;
    const filterlogohome = teamsInfo?.filter(
      (x) => x?._id === values?.teams?.[0]?.team
    )[0]?.logo;
    const filternamehome = teamsInfo?.filter(
      (x) => x?._id === values?.teams?.[0]?.team
    )[0]?.name;
    const filterscorehome = values?.teams?.[0]?.score;

    const filterlogoaway = teamsInfo?.filter(
      (x) => x?._id === values?.teams?.[1]?.team
    )[0]?.logo;
    const filternameaway = teamsInfo?.filter(
      (x) => x?._id === values?.teams?.[1]?.team
    )[0]?.name;
    const filterscoreaway = values?.teams?.[1]?.score;
    return (
      <>
        <div style={{ minWidth: isMobile ? "200px" : "300px" }}>
          <span
            className="team_name_logo"
            style={{
              display: "inline-block",
              width: isMobile ? "200px" : "80%",
              color: won === 0 ? "green" : "red",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: isMobile ? "150px" : "200px",
                minWidth: "100px",
              }}
            >
              <img
                src={
                  Boolean(filterlogohome)
                    ? filterlogohome
                    : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? "https://tcbl.ca/logos/logo.png"
                    : "https://tcbl2.poindexters.ca/img/loc.jpg"
                }
                height="10px"
                width="10px"
                alt=""
              />
              <span className="ml-2">{filternamehome}</span>
            </span>
            <span>{filterscorehome}</span>
          </span>
          <span
            className="team_name_logo"
            style={{
              display: "inline-block",
              width: isMobile ? "200px" : "80%",
              color: won === 1 ? "green" : "red",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: isMobile ? "150px" : "200px",
                minWidth: "100px",
              }}
            >
              <img
                src={
                  Boolean(filterlogoaway)
                    ? filterlogoaway
                    : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? "https://tcbl.ca/logos/logo.png"
                    : "https://tcbl2.poindexters.ca/img/loc.jpg"
                }
                height="10px"
                width="10px"
                alt=""
              />
              <span className="ml-2">{filternameaway}</span>
            </span>
            <span>{filterscoreaway}</span>
          </span>
        </div>
      </>
    );
  };
  const scoreTitleStyles = isMobile
    ? { color: "#000000" }
    : { color: "#000000" };
  const scoreColumns = [
    {
      title: <span style={scoreTitleStyles}>{General_Constants.TEAM}</span>,
      width: "200px",
      fixed: "left",
      render: (record, text, k) => <div>{teamsNameLogo(record)}</div>,
    },
    {
      title: <span style={scoreTitleStyles}>{General_Constants.DATE}</span>,
      width: "200px",
      key: "matchDate",
      dataIndex: "matchDate",
      render: (text) => (
        <div style={{ textAlign: "left", fontWeight: "500", color: "#000000" }}>
          {text}
        </div>
      ),
    },
    {
      title: <span style={scoreTitleStyles}>{General_Constants.TIME}</span>,
      width: "200px",
      key: "matchTime",
      dataIndex: "matchTime",
      render: (text, record) =>
        getDateFormat({ date: record?.date, format: "hh:mm a" }), //JSON.stringify(dayjs(Date.parse(record?.date)).add(-3,'hours').format('HH:mm:ss'))
    },
    {
      title: <span style={scoreTitleStyles}>{General_Constants.STATUS}</span>,
      width: "200px",
      key: "matchStatus",
      dataIndex: "status",
      render: (text, values) => (
        <div style={scoreTitleStyles}>
          {values?.status === "Completed"
            ? "Final"
            : values?.is_halftime_taken
            ? "Half Time"
            : values?.status === "Scheduled"
            ? "Scheduled"
            : values?.status}
        </div>
      ),
    },
  ];
  const onTabChange = (e) => {
    setCurrent(e.key);
  };
  //const [form] = Form.useForm();
  //Various List of Filters
  const [totalleaguedata, setTotalleaguedata] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seasonList, setSeasonList] = useState([]);
  const [tiersList, setTiersList] = useState(["All"]);
  const [matchesList, setMatchesList] = useState([]);

  //Various Selected Filter
  const [league, setLeague] = useState("Sunday League");
  const [tier, setTier] = useState("1");
  const [season, setSeason] = useState("");

  //Final Table data
  const [tableData, settableData] = useState([]);
  const [rankingDataSource, setRankingDataSource] = useState([]);
  //const [playerGameLogs, setPlayerGameLogs] = useState([]);
  const getDateFormat = (props, flag) => {
    const dFormat = "MMM DD, YYYY";
    if (typeof props === "object") {
      const { date, format = dFormat, isTime } = props || {};
      if (!date) return;
      if (isTime) return dayjs(date).format(`${format} hh:mm a`);
      return dayjs(date).format(format);
    } else {
      if (flag) return props ? dayjs(props) : dayjs();
      return props ? dayjs(props).format(`${dFormat}`) : dayjs();
    }
  };
  const [matchstats, setmatchstats] = useState({});
  const [openmatchstats, setopenmatchstats] = useState(false);
  const logColumns = [
    {
      title: (
        // <span style={standingsColumnsTitleStyle}>{General_Constants.GAME}</span>
        <span style={standingsColumnsTitleStyle}>{""}</span>
      ),
      // dataIndex: "team",
      // key: "team",
      // width: isMobile ? "200px" : "40%",
      width: "100%",
      //filterSearch: true,
      //filters: [],
      fixed: "left",
      align: "left",
      render: (record) => {
        console.log("recordSIngleyeam", record);
        return (
          <div>
            <AlternateTeamsNameLogo
              record={record}
              teamsInfo={teamsInfo}
              settings={settings}
              setmatchstats={setmatchstats}
              setopenmatchstats={setopenmatchstats}
            />
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <span style={standingsColumnsTitleStyle}>{General_Constants.DATE}</span>
    //   ),
    //   dataIndex: "date",
    //   key: "date",
    //   width: isMobile ? "100px" : "150px",
    //   //width: "200px",
    //   //sorter: true,
    //   //sortDirections: ["ascend", "descend"],
    //   render: (item) => (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <span style={{ borderRadius: "2px 2px 0px 0px" }}>
    //         {dayjs(new Date(item)).format("MMM DD")}
    //       </span>
    //       <span style={{ borderRadius: "0px 0px 2px 2px" }}>
    //         {dayjs(new Date(item)).format("h:mm a")}
    //       </span>
    //     </div>
    //   ),
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>Court</span>,
    //   dataIndex: "status",
    //   key: "status",
    //   width: isMobile ? "100px" : undefined,
    //   //width: "80px",
    //   //filters: ["Confirmed", "Scheduled", "Assigned", "Started", "Completed", "Cancelled"].map(ee => ({ text: ee, value: ee.toString() })),
    //   render: (text, record) => <div>{record.court.name}</div>,
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>Season</span>,
    //   dataIndex: "season",
    //   key: "season",
    //   width: isMobile ? "100px" : undefined,
    //   //filters: [],
    //   //filterSearch: true,
    //   //width: "120px",
    //   render: (text, record) => record?.sports_event?.name,
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>League</span>,
    //   dataIndex: "league",
    //   key: "league",
    //   width: isMobile ? "100px" : undefined,
    //   //filterSearch: true,
    //   //filters: [],
    //   //width: "160px",
    //   render: (text, record) => record?.league?.name,
    // },
    // {
    //   title:  <span style={standingsColumnsTitleStyle}>Type</span>,
    //   dataIndex: "tier_type",
    //   key: "tier_type",
    //   width: "50px",
    //   //filters: ["Regular", "CO-ED"].map(ee => ({ text: ee, value: ee }))
    //   // render: (text, record) => record?.tier_type
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>Division</span>,
    //   dataIndex: "tier",
    //   key: "tier",
    //   width: isMobile ? "100px" : undefined,
    //   //width: "100px",
    //   //filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
    //   //filterSearch: true,
    //   render: (text, record) =>
    //     process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
    //       ? record?.tier
    //         ? record?.tier === "N/A"
    //           ? `${(
    //               settings?.divisions?.[record?.home?.tier] ?? text
    //             ).toUpperCase()} & ${(
    //               settings?.divisions?.[record?.away?.tier] ?? text
    //             ).toUpperCase()}`
    //           : //`Tier ${record?.home?.tier} & ${record?.away?.tier}`
    //             (settings?.divisions?.[text] ?? text).toUpperCase() //`Tier ${record?.tier}`
    //         : "-"
    //       : record?.tier === "1"
    //       ? "East"
    //       : "West",
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>Status</span>,
    //   dataIndex: "status",
    //   key: "status",
    //   width: isMobile ? "100px" : undefined,
    //   //width: "80px",
    //   //filters: ["Confirmed", "Scheduled", "Assigned", "Started", "Completed", "Cancelled"].map(ee => ({ text: ee, value: ee.toString() })),
    //   render: (text, record) => (
    //     <div>
    //       {text === "Completed" && record?.publishstatus === "Published"
    //         ? "Completed"
    //         : "Pending"}
    //     </div>
    //   ),
    // },
    // {
    //   title:  <span style={standingsColumnsTitleStyle}>Round</span>,
    //   dataIndex: "round",
    //   key: "round",
    //   width: "100px",
    //   render: (text, record) => {
    //     if (record?.round_no === 0) return 'Playoffs'
    //     if (record?.round_no === -1) return 'N/A'
    //     return `Round ${record.round_no}`
    //   }
    // },
  ];
  // const [logColumns, setLogColumns] = useState([
  //   {
  //     title: "Games",
  //     dataIndex: "games",
  //     name: "games",
  //     render: (text, gl) => {
  //       return (
  //         <div style={{ cursor: "pointer" }}>
  //           <span class={`pl-team-name ${gl?.match?.home?.team?._id === gl?.team?._id ? 'pl-team' : ''}`}>{gl?.match?.home?.team?.name}</span>
  //           vs.
  //           <span class={`pl-team-name pl-away ${gl?.match?.away?.team?._id === gl?.team?._id ? 'pl-team' : ''}`}>{gl?.match?.away?.team?.name}</span>
  //         </div>
  //       )
  //     }
  //   }
  // ])
  // const loadGameLogs = async (sportEvent, statFields = []) => {
  //   //setLogsLoading(true);
  //   const tmp = statFields.map((ee = '') => ({
  //     title: ee.toUpperCase(),
  //     key: ee,
  //     dataIndex: ee
  //   }))
  //   const { data } = await api.get(`${env?.API_ENDPOINT_URL}/settings/logs`);
  //   //setLogsLoading(false);
  //   setLogColumns([...logColumns, ...tmp])
  //   const gameLogs = [];
  //   data.game_logs.forEach(gl => {
  //     const payload = { ...gl, ...gl.stats };
  //     gameLogs.push(payload);
  //   })
  //   setPlayerGameLogs(gameLogs);
  // }
  const getleagues = () => {
    setloading(true);
    api
      .get(`${env?.API_ENDPOINT_URL}/settings/get-leagues?type=Regular`)
      .then((res) => {
        //update
        // setLeaguesList(res?.data);
        //setLeague(res?.data?.[res?.data?.length - 1]?.name ?? "");
        // form.setFieldsValue({
        //   league: res?.data?.[res?.data?.length - 1]?.name ?? "",
        // });
      });

    setloading(false);
  };
  const getFullName = (props) => {
    if (!props) return;
    const { first_name = "", last_name = "" } = props;
    if (first_name && last_name) {
      return first_name + " " + last_name;
    }
    return first_name || "";
  };
  const TeamNameWithLogo = (props) => {
    const { logo, name, score, winLoss } = props || {};
    if (isMobile) {
      return (
        <div>
          <span style={{ marginLeft: "6px" }}>
            {name} ({score})
          </span>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div>
          <img
            src={
              logo
                ? logo
                : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
                ? "https://tcbl.ca/logos/logo.png"
                : "https://tcbl2.poindexters.ca/img/loc.jpg"
            }
            alt=""
            height="50px"
            width={"50px"}
          />
        </div>
        <div style={{ paddingLeft: "6px" }}>
          <div>
            {name} ({score})
          </div>
          <div style={{ fontWeight: "600" }}>{winLoss}</div>
        </div>
      </div>
    );
  };
  const getNameofstats = (statname) => {
    let name = "";
    switch (statname) {
      case "name":
        name = "Players";
        break;
      case "pf":
        name = "Personal Foul";
        break;
      default:
        name = statname;
        break;
    }
    return name;
  };
  const MatchView = ({ match, key }) => {
    const [columns, setColumns] = useState([]);
    console.log("matchchec", match);
    const homeTotalWins = match?.home?.wins;
    const homeTotalLoss = match?.home?.losses;

    const awayTotalWins = match?.away?.wins;
    const awayTotalLoss = match?.away?.losses;

    useEffect(() => {
      // const stats = (
      //   Object.keys(match?.home?.players[0]?.stats || {}) || []
      // ).filter((e) => ["_id", "created_at", "updated_at"].indexOf(e) === -1);
      // const allowedStatsHeaders = ['name','blk','stl','reb','pts','ast']
      const columns = true
        ? [
            {
              title: (
                <div
                  style={{
                    fontWeight: "700",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  <span className={styles.ranking}>
                    <img src={rankicon} alt="#" width="15px" />
                  </span>{" "}
                  <span>Name</span>
                </div>
              ),
              dataIndex: "name",
              fixed: "left",
              // width: isMobile ? "100px" : undefined,
              key: "name",
              width: 100,
              render: (record, stats) => (
                <div
                  style={
                    isMobile
                      ? {
                          width: isMobile ? "100px" : undefined,
                          color: record === "Total" ? "#000" : undefined,
                          fontWeight: record === "Total" ? 700 : undefined,
                          fontSize: record === "Total" ? "14px" : "12px",
                          textAlign: "left",
                          paddingLeft: "2px",
                        }
                      : {
                          width: isMobile ? "100px" : "150px",
                          color: record === "Total" ? "#000" : undefined,
                          fontWeight: record === "Total" ? 700 : undefined,
                          fontSize: record === "Total" ? "16px" : undefined,
                          textAlign: "left",
                          paddingLeft: "2px",
                        }
                  }
                >
                  {`${record.split(" ")?.[0]} ${
                    record.split(" ")?.[1]?.[0] ?? ""
                  }${record.split(" ")?.[1]?.[0] ? "." : ""}`}
                </div>
              ),
            },
            {
              title: (
                <span>
                  <img src={basktetball} alt="#" width="15px" />
                </span>
              ),
              dataIndex: "name",
              key: "name",
              width: isMobile ? "70px" : "50px",
              render: (record, stats) =>
                record === "Total" ? (
                  <></>
                ) : (
                  <span style={{ display: "inline-block", width: "25px" }}>
                    <span style={{ fontSize: "10px" }}>#</span>
                    {stats?.jersey_no}
                  </span>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  PTS
                </span>
              ),
              dataIndex: "pts",
              key: "pts",
              width: "50px",
              sorter: (a, b, c) => {
                if (
                  (a.name === "Total" && b.name === "Team") ||
                  (a.name === "Total" && b.name !== "Team") ||
                  (a.name === "Team" && b.name !== "Total")
                ) {
                  return c === "ascend" ? 1 : -1;
                } else {
                  return a.to - b.to;
                }
              },
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <div>DNP</div>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  FGM
                </span>
              ),
              dataIndex: "fgm",
              width: "50px",
              key: "fgm",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record.fgm).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  FGA
                </span>
              ),
              dataIndex: "fga",
              width: "50px",
              key: "fga",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record.fga).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>FG%</span>
              ),
              dataIndex: "2pta",
              width: "50px",
              key: "2pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["fga"])
                      ? parseFloat(
                          (record["fgm"] / record["fga"]) * 100
                        ).toFixed(1)
                      : parseFloat(0).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  2PM
                </span>
              ),
              width: "50px",
              dataIndex: "2ptm",
              key: "2ptm",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["2ptm"])
                      ? parseFloat(record["2ptm"]).toFixed(0)
                      : 0}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  2PA
                </span>
              ),
              dataIndex: "2pta",
              width: "50px",
              key: "2pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["2pta"])
                      ? parseFloat(record["2pta"]).toFixed(0)
                      : 0}
                  </div>
                ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>2P%</span>
              ),
              dataIndex: "2pta",
              width: "50px",
              key: "2pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["2pta"])
                      ? parseFloat(
                          (record["2ptm"] / record["2pta"]) * 100
                        ).toFixed(1)
                      : parseFloat(0).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  3PM
                </span>
              ),
              width: "50px",
              dataIndex: "3ptm",
              key: "3ptm",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["3ptm"])
                      ? parseFloat(record["3ptm"]).toFixed(0)
                      : 0}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  3PA
                </span>
              ),
              dataIndex: "3pta",
              width: "50px",
              key: "3pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["3pta"])
                      ? parseFloat(record["3pta"]).toFixed(0)
                      : 0}
                  </div>
                ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>3P%</span>
              ),
              dataIndex: "2pta",
              width: "50px",
              key: "2pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["3pta"])
                      ? parseFloat(
                          (record["3ptm"] / record["3pta"]) * 100
                        ).toFixed(1)
                      : parseFloat(0).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  FTM
                </span>
              ),
              dataIndex: "ftm",
              key: "ftm",
              width: "50px",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record.ftm).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  FTA
                </span>
              ),
              dataIndex: "fta",
              width: "50px",
              key: "fta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record["fta"]).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>FT%</span>
              ),
              dataIndex: "2pta",
              width: "50px",
              key: "2pta",
              render: (text, record) =>
                Boolean(record?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color:
                              record?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              record?.name === "Total" ? 700 : undefined,
                            fontSize:
                              record?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {Boolean(record["fta"])
                      ? parseFloat(
                          (record["ftm"] / record["fta"]) * 100
                        ).toFixed(1)
                      : parseFloat(0).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  REB
                </span>
              ),
              dataIndex: "reb",
              key: "reb",
              width: "50px",
              sorter: (a, b) => a.reb - b.reb,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  OREB
                </span>
              ),
              dataIndex: "oreb",
              key: "oreb",
              width: "60px",
              sorter: (a, b) => a.oreb - b.oreb,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  DREB
                </span>
              ),
              dataIndex: "dreb",
              key: "dreb",
              width: "60px",
              sorter: (a, b) => a.dreb - b.dreb,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  AST
                </span>
              ),
              dataIndex: "ast",
              key: "ast",
              width: "50px",
              sorter: (a, b) => a.ast - b.ast,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  STL
                </span>
              ),
              dataIndex: "stl",
              key: "stl",
              width: "50px",
              sorter: (a, b) => a.stl - b.stl,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  BLK
                </span>
              ),
              dataIndex: "blk",
              key: "blk",
              width: "50px",
              sorter: (a, b) => a.blk - b.blk,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  PF
                </span>
              ),
              dataIndex: "pf",
              key: "pf",
              width: "40px",
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record ?? 0).toFixed(0) ?? 0}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  TF
                </span>
              ),
              dataIndex: "tf",
              key: "tf",
              width: "40px",
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record ?? 0).toFixed(0) ?? 0}
                  </div>
                ),
            },
            {
              title: (
                <span
                  style={
                    isMobile
                      ? {
                          fontWeight: "700",
                          color: "#000",
                          textAlign: "center",
                        }
                      : { fontWeight: "700", color: "#000" }
                  }
                >
                  TO
                </span>
              ),
              dataIndex: "to",
              key: "to",
              width: "40px",
              sorter: (a, b) => a.to - b.to,
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            textAlign: "center",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {parseFloat(record).toFixed(0)}
                  </div>
                ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>EF</span>
              ),
              dataIndex: "ef",
              key: "ef",
              width: "40px",
              render: (record, stats) =>
                Boolean(stats?.dnp) ? (
                  <></>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                          }
                        : {
                            color: stats?.name === "Total" ? "#000" : undefined,
                            fontWeight:
                              stats?.name === "Total" ? 700 : undefined,
                            fontSize:
                              stats?.name === "Total" ? "16px" : undefined,
                          }
                    }
                  >
                    {record}
                  </div>
                ),
            },
          ]
        : [
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>
                  <span className={styles.ranking}>
                    <img src={rankicon} alt="#" width="15px" />
                  </span>{" "}
                  <span>Name</span>
                </span>
              ),
              dataIndex: "name",
              fixed: "left",
              width: isMobile ? "100px" : undefined,
              key: "name",
              render: (record) => (
                <div
                  style={
                    isMobile
                      ? {
                          fontSize: "12px",
                          width: isMobile ? "100px" : undefined,
                        }
                      : undefined
                  }
                >
                  {`${record.split(" ")?.[0]} ${record.split(" ")?.[1]?.[0]}.`}
                </div>
              ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>2P</span>
              ),
              dataIndex: "2ptm",
              key: "2ptm",
              render: (text, record) => (
                <div style={isMobile ? { fontSize: "12px" } : undefined}>
                  {Boolean(record["2ptm"]) ? record["2ptm"] : 0}/
                  {Boolean(record["2pta"]) ? record["2pta"] : 0}
                </div>
              ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>3P</span>
              ),
              dataIndex: "3ptm",
              key: "3ptm",
              render: (text, record) => (
                <div style={isMobile ? { fontSize: "12px" } : undefined}>
                  {Boolean(record["3ptm"]) ? record["3ptm"] : 0}/
                  {Boolean(record["3pta"]) ? record["3pta"] : 0}
                </div>
              ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>TO</span>
              ),
              dataIndex: "to",
              key: "to",
              render: (record) => (
                <div style={isMobile ? { fontSize: "12px" } : undefined}>
                  {record}
                </div>
              ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>PF</span>
              ),
              dataIndex: "pf",
              key: "pf",
              render: (record) => (
                <div style={isMobile ? { fontSize: "12px" } : undefined}>
                  {record ?? 0}
                </div>
              ),
            },
            {
              title: (
                <span style={{ fontWeight: "700", color: "#000" }}>TF</span>
              ),
              dataIndex: "tf",
              key: "tf",
              render: (record) => (
                <div style={isMobile ? { fontSize: "12px" } : undefined}>
                  {record ?? 0}
                </div>
              ),
            },
          ];
      // /{(record.pts+record.rebs+record.ast+record.stl+record.blk+record.fga+record.fgm)-(record.to+record.ftm+record.fgm)}
      setColumns([...columns]);
      // eslint-disable-next-line
    }, []);
    const getDateSource = (statdata) => {
      const dataarray = {
        is_captain: false,
        is_starting_lineup: false,
        jersey_no: "100",
        name: "Total",
        order: 100,
        personal_fouls: 0,
        pts: statdata?.[0]?.violation ?? 0,
        "2pta": 0,
        "2ptm": 0,
        "3pta": 0,
        "3ptm": 0,
        ast: 0,
        blk: 0,
        dnp: false,
        dreb: 0,
        ef: 0,
        fga: 0,
        fgm: 0,
        fta: 0,
        ftm: 0,
        oreb: 0,
        pf: 0,
        reb: 0,
        stl: 0,
        tf: 0,
        to: 0,
      };
      const dataarrayt = {
        is_captain: false,
        is_starting_lineup: false,
        jersey_no: "",
        name: "Team",
        order: 101,
        personal_fouls: 0,
        pts: statdata?.[0]?.violation,
        "2pta": 0,
        "2ptm": 0,
        "3pta": 0,
        "3ptm": 0,
        ast: 0,
        blk: 0,
        dnp: false,
        dreb: 0,
        ef: 0,
        fga: 0,
        fgm: 0,
        fta: 0,
        ftm: 0,
        oreb: 0,
        pf: 0,
        reb: 0,
        stl: 0,
        tf: 0,
        to: 0,
      };
      const v1 = statdata.forEach((item) => {
        dataarray.pts += item.pts;
        dataarray.personal_fouls += item.personal_fouls;
        dataarray["2pta"] += item["2pta"];
        dataarray["2ptm"] += item["2ptm"];
        dataarray["3pta"] += item["3pta"];
        dataarray["3ptm"] += item["3ptm"];
        dataarray.ast += item.ast;
        dataarray.blk += item.blk;
        dataarray.dreb += item.dreb;
        dataarray.ef += item.ef;
        dataarray.fga += item.fga;
        dataarray.fgm += item.fgm;
        dataarray.fta += item.fta;
        dataarray.ftm += item.ftm;
        dataarray.oreb += item.oreb;
        dataarray.pf += item.pf;
        dataarray.reb += item.reb;
        dataarray.stl += item.stl;
        dataarray.tf += item.tf;
        dataarray.to += item.to;
      });
      return [...statdata, dataarrayt, dataarray];
    };

    return (
      <div
        key={key}
        className={styles.container}
        style={
          isMobile
            ? { marginLeft: "-20px", padding: "0px", width: "calc(95vw)" }
            : undefined
        }
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            key="1"
            tab={
              <div
                className={`mteam-name${
                  match?.home?.team?._id === match?.won_by?.team._id
                    ? " w-team"
                    : ""
                }`}
              >
                {isMobile ? (
                  <span>
                    {
                      <TeamNameWithLogo
                        {...match?.home?.team}
                        score={match?.home?.score || 0}
                        winLoss={`${homeTotalWins}-${homeTotalLoss}`}
                      />
                    }
                  </span>
                ) : (
                  <Tag
                    color={
                      (parseInt(match?.home?.score) || 0) >
                      parseInt(match?.away?.score || 0)
                        ? "green"
                        : "blue"
                    }
                    style={{ padding: "5px" }}
                  >
                    <TeamNameWithLogo
                      {...match?.home?.team}
                      score={match?.home?.score || 0}
                      winLoss={`${homeTotalWins}-${homeTotalLoss}`}
                    />
                  </Tag>
                )}
              </div>
            }
          >
            <div className="mteam-item">
              {match?.jerseyviolation?.home ||
              (Boolean(match?.publishstatus !== "Published") &&
                Boolean(match?.status === "Completed")) ? (
                <>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "#AA4A44",
                      fontWeight: "700",
                    }}
                  >
                    Stats Violation
                  </div>
                  <div
                    style={{
                      color: "#AA4A44",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "22px",
                      fontColor: "#000",
                    }}
                  >
                    {Boolean(match?.publishstatus !== "Published") &&
                    Boolean(match?.status === "Completed")
                      ? "The stats for this Match is pending"
                      : Boolean(match?.jerseyviolation?.homemessage)
                      ? match?.jerseyviolation?.homemessage
                      : "Insufficient Players: The team roster lacks the minimum requirement of 5 players."}
                  </div>
                </>
              ) : (
                <Table
                  loading={loading}
                  pagination={false}
                  bordered={false}
                  dataSource={getDateSource(
                    match?.home?.players
                      ?.map((e) => ({
                        name: getFullName(e.player),
                        ...e.stats,
                        pf: e.personal_fouls,
                        tf: e.technical_fouls,
                        jersey_no: e.jersey_no,
                        violation: match?.home?.violation ?? 0,
                      }))
                      .filter((item) => !item.dnp)
                      .sort((a, b) =>
                        a.dnp === b.dnp
                          ? a.pts < b.pts
                            ? 0
                            : -1
                          : a.dnp > b.dnp
                          ? 0
                          : -1
                      )
                  )}
                  columns={columns}
                  style={
                    isMobile
                      ? { marginLeft: "-3px", width: "calc(95vw)" }
                      : undefined
                  }
                  scroll={{
                    x: isMobile ? 800 : 1200,
                    y: 400,
                  }}
                />
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="2"
            tab={
              <div
                className={`mteam-name${
                  match?.away?.team?._id === match?.won_by?.team._id
                    ? " w-team"
                    : ""
                }`}
              >
                {isMobile ? (
                  <span>
                    {
                      <TeamNameWithLogo
                        {...match?.away?.team}
                        score={match?.away?.score || 0}
                        winLoss={`${awayTotalWins}-${awayTotalLoss}`}
                      />
                    }
                  </span>
                ) : (
                  <Tag
                    color={
                      parseInt(match?.home?.score || 0) >
                      parseInt(match?.away?.score || 0)
                        ? "blue"
                        : "green"
                    }
                    style={{ padding: "5px" }}
                  >
                    {
                      <TeamNameWithLogo
                        {...match?.away?.team}
                        score={match?.away?.score || 0}
                        winLoss={`${awayTotalWins}-${awayTotalLoss}`}
                      />
                    }
                  </Tag>
                )}
              </div>
            }
          >
            <div className="mteam-item">
              {match?.jerseyviolation?.away ||
              (Boolean(match?.publishstatus !== "Published") &&
                Boolean(match?.status === "Completed")) ? (
                <>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "#AA4A44",
                      fontWeight: "700",
                    }}
                  >
                    Stats Violation
                  </div>
                  <div
                    style={{
                      color: "#AA4A44",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "22px",
                      fontColor: "#000",
                    }}
                  >
                    {/* <span style={{marginTop:'-70px',marginRight:'-145px', fontSize:'20px',color:'#AA4A44',textDecoration:'underline'}}>Stats Violation</span> */}
                    {Boolean(match?.publishstatus !== "Published") &&
                    Boolean(match?.status === "Completed")
                      ? "The stats for this Match is pending"
                      : Boolean(match?.jerseyviolation?.awaymessage)
                      ? match?.jerseyviolation?.awaymessage
                      : "Insufficient Players: The team roster lacks the minimum requirement of 5 players."}
                  </div>
                </>
              ) : (
                <Table
                  loading={loading}
                  pagination={false}
                  dataSource={getDateSource(
                    match?.away?.players
                      ?.map((e) => ({
                        name: e.is_me ? (
                          <span style={{ color: "#4bb543" }}>
                            {getFullName(e.player)}(You)
                          </span>
                        ) : (
                          getFullName(e.player)
                        ),
                        ...e.stats,
                        pf: e.personal_fouls,
                        tf: e.technical_fouls,
                        jersey_no: e.jersey_no,
                        violation: match?.away?.violation ?? 0,
                      }))
                      .sort((a, b) =>
                        a.dnp === b.dnp
                          ? a.pts < b.pts
                            ? 0
                            : -1
                          : a.dnp > b.dnp
                          ? 0
                          : -1
                      )
                  )}
                  columns={columns}
                  scroll={{
                    x: isMobile ? 800 : 1200,
                    y: 400,
                  }}
                />
              )}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  };

  const [count, setCount] = useState(0);
  const [matches, setMatches] = useState([]);

  const loadMatches = async () => {
    //setListing(true)
    const tempLeague =
      leaguesList.find((item) => item?.name === league)?.value ??
      "626fad6d75280628c46c18c8";
    let filter = "";
    if (Boolean(season)) {
      filter += `&season=${JSON.stringify([season])}`;
    }
    if (Boolean(tier) && tier !== "All") {
      filter += `&tier=${JSON.stringify([tier])}`;
    }
    //todo fix for not working
    setloading(true);
    const { data } = await api.get(
      `${env?.API_ENDPOINT_URL}/settings/logs?limit=100&league=${JSON.stringify(
        [tempLeague]
      )}${filter}`
    );
    setloading(false);
    const tmp = data?.results
      ?.filter(
        (item) =>
          item?.publishstatus === "Published" ||
          item?.status === "Started" ||
          item?.status === "Completed"
      )
      ?.map((ee) => ({ ...ee, key: ee._id }))
      ?.sort((a, b) => new Date(b?.date) - new Date(a?.date));
    setCount(tmp.count);
    setMatches([...tmp]);
  };
  useEffect(() => {
    if (current === "stats") {
      loadMatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, tier, league, season]);
  const [leaderstats, setleaderstats] = useState([]);
  const getleaders = async () => {
    try {
      const leagueid = leaguesList?.find((item) => item.name === league);
      setloading(true);
      const { data } = await api.post(
        `${env?.API_ENDPOINT_URL}/settings/getLeaders`,
        {
          leaderstype: leaderstype,
          season: season,
          league: leagueid?.value,
          tier: tier,
        }
      );
      setloading(false);
      setleaderstats([...data]);
    } catch (e) {
      console.log(e, "error Occured");
    }
  };
  useEffect(() => {
    getleaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderstype, season, league, tier]);
  const getSeasonStandingsbyTier = () => {
    let url = `${env?.API_ENDPOINT_URL}/settings/get-seasons?league=${
      league?.toLowerCase() ?? "sunday league"
    }`; //&season=${season}
    // if (Boolean(season)) {
    //   url += `&season=${season}`;
    // }
    // if (Boolean(tier)) {
    //   url += `&tier=${tier}`;
    // }
    setloading(true);
    api
      .get(url)
      .then((res) => {
        setTotalleaguedata([...res?.data?.data]);
        let data = [...res?.data?.data?.filter((item) => item?._id === season)];
        // if ([...res?.data?.data]?.length) {
        //   data = [...res?.data?.data]?.find((item) => item?._id === season) ?? [];
        // } else {
        //   data = tableData?.[0] ?? [];
        // }
        // const tierslist = data?.tiers?.map((item) => item?.tier);
        // //update
        // setTiersList(tierslist ?? []);
        // setTier(tierslist?.[0] ?? null);
        settableData([...res?.data?.data]);
        setRankingDataSource([
          ...data?.[0]?.tiers?.filter((item) => item?.tier === tier),
        ]);
      })
      .catch((err) => console.log(err, "error Occured"));
    setloading(false);
  };
  useEffect(() => {
    if (season) {
      if (current === "standings") {
        getSeasonStandingsbyTier();
      }
      //  else if(current==='standings') {
      //   getseasonscores()
      // }
    }
  }, [league]); //season,tier
  useEffect(() => {
    if (season) {
      if (current === "scores") {
        getseasonscores();
      }
    }
  }, [season, league, tier, current]); //season,tier
  useEffect(() => {
    if (season && Boolean(tableData?.[0])) {
      let data = [...tableData?.filter((item) => item?._id === season)];
      if (Boolean(data?.[0]?.tiers?.length)) {
        setRankingDataSource([
          ...data?.[0]?.tiers?.filter((item) => item?.tier === tier),
        ]);
      } else {
        setRankingDataSource([]);
      }
    }
  }, [season, tier]);
  const getseasonstandings = (name, seasonname) => {
    let url = `${env?.API_ENDPOINT_URL}/settings/get-seasons?league=${
      name ?? "sunday league"
    }`;
    if (Boolean(seasonname)) {
      url += `&season=${seasonname}`;
    }

    setloading(true);
    api
      .get(url)
      .then((res) => {
        //update
        // setSeasonList(res?.data?.seasons ?? []);
        // const tierslist = res?.data?.data?.[0]?.tiers?.map(
        //   (item) => item?.tier
        // );
        // setTiersList(tierslist ?? []);
        // setTier(tierslist?.[0] ?? null);
        // settableData(res?.data?.data?.[0]?.tiers);
        // setTotalleaguedata(res?.data?.data);
        // form.setFieldsValue({
        //   tier: tierslist?.[0],
        //   season: res?.data?.seasons?.[0]?.value,
        // });
        // setSeason(res?.data?.seasons?.[0]?.value);
      })
      .catch((err) => console.log(err, "error Occured"));
    setloading(false);
  };

  const getseasonscores = () => {
    let url =
      Boolean(tier) && tier !== "All"
        ? `${
            env?.API_ENDPOINT_URL
          }/settings/get-matches-mobile-with-tier?season=${
            season ?? seasonList?.[0]?.value
          }&league=${league ?? "sunday league"}&tier_type=Regular&tier=${tier}`
        : `${
            env?.API_ENDPOINT_URL
          }/settings/get-matches-mobile-with-all-tier?season=${
            season ?? seasonList?.[0]?.value
          }&league=${league ?? "sunday league"}&tier_type=Regular`;

    // api.get(url).then((res) => {
    //   setSeasonList(res?.data?.seasons ?? []);
    //   const tierslist = res?.data?.data?.[0]?.tiers?.map((item) => item?.tier)
    //   setTiersList(tierslist??[]);
    //   setTier(tierslist?.[0]??null)
    //   settableData(res?.data?.data?.[0]?.tiers);
    //   setTotalleaguedata(res?.data?.data)
    //   form.setFieldsValue({tier:tierslist?.[0],season:res?.data?.seasons?.[0]?.value})
    //   setSeason(res?.data?.seasons?.[0]?.value)
    // }).catch((err)=>console.log(err,'error Occured'));

    setloading(true);
    api
      .get(url)
      .then((res) => {
        setMatchesList([
          ...res?.data?.filter((item) => item.status !== "Scheduled"),
        ]);
      })
      .catch((err) => console.log(err, "error Occured"));
    setloading(false);
  };
  // Update
  // useEffect(() => {
  //   getleagues();
  //   getseasonstandings("sunday league");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (season && current === "standings") {
  //     getseasonstandings(league?.toLowerCase(), "");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [league]);
  // useEffect(() => {
  //   if (season && current === "standings") {
  //     getseasonstandings(league?.toLowerCase(), season);
  //   } else if (season && current === "scores") {
  //     getseasonstandings(league?.toLowerCase(), season);
  //     getseasonscores();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ current]);
  // useEffect(() => {
  //   if (season && current === "standings") {
  //     getseasonstandings(league?.toLowerCase(), season);
  //   } else if (season && current === "scores") {
  //     getseasonscores();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [season,tier]);

  useEffect(() => {
    let data = [];
    if (totalleaguedata?.length) {
      data = totalleaguedata?.find((item) => item?._id === season) ?? [];
    } else {
      data = tableData?.[0] ?? [];
    }
    const tierslist = data?.tiers?.map((item) => item?.tier);
    //update
    // setTiersList(tierslist ?? []);
    // setTier(tierslist?.[0] ?? null);
    // settableData(data?.tiers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);
  const [allLeagueListasObject, setAllLeagueListasObject] = useState({});
  useEffect(() => {
    let data = [];
    if (tiersList.length) {
      data = tableData?.filter((item) => item?.tier === tier) ?? [];
    } else {
      data = tableData?.[0] ?? [];
    }
    if (current !== "scores") {
      //setRankingDataSource({...data});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tier, season]);
  //const { Option } = Select;
  // const uniqueTags = [];
  // eslint-disable-next-line array-callback-return
  // matchesList.map((img) => {
  //   if (uniqueTags.indexOf(img.matchDate) === -1) {
  //     uniqueTags.push(img.matchDate);
  //   }
  // });
  const loadTeamsInfo = async () => {
    setloading(true);
    const response = await api.get(
      `${env?.API_ENDPOINT_URL}/settings/get-teams-logo`
    );
    setloading(false);
    setteamsInfo(response?.data?.teamsInfo);
  };
  useEffect(() => {
    if (!Boolean(teamsInfo?.[0])) {
      loadTeamsInfo();
    }
    setCurrent("stats");
  }, []);
  const leagueStyle = isMobile
    ? {
        border: "0px solid black",
        padding: "5px 0px",
        margin: "0px -10px",
        backgroundColor: "white",
      }
    : {
        border: "3px solid black",
        padding: "5px",
        backgroundColor: "white",
      };

  return (
    <div style={leagueStyle}>
      <Menu
        onClick={onTabChange}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />

      {/* {isMobile?<FilterTwoTone />:null} */}

      <div style={{ minHeight: "330px", padding: "20px 20px" }}>
        {/* <div
          style={{ fontSize: "28px", fontWeight: "700", margin: "20px 0px",color:'#000' }}
        >
          {current?.toUpperCase()}
        </div> */}
        <Filter
          leaguesList={leaguesList}
          setLeaguesList={setLeaguesList}
          league={league}
          setLeague={setLeague}
          season={season}
          setSeason={setSeason}
          seasonList={seasonList}
          setSeasonList={setSeasonList}
          tier={tier}
          setTier={setTier}
          tiersList={tiersList}
          setTiersList={setTiersList}
          allLeagueListasObject={allLeagueListasObject}
          setAllLeagueListasObject={setAllLeagueListasObject}
          current={current}
          leaderstats={leaderstats}
          setleaderstype={setleaderstype}
          detailType={detailType}
          setdetailType={setdetailType}
        />
        {isMobile ? (
          <></>
        ) : (
          // <div style={{position:'relative',top:'10px'}}>
          //   <Form form={form}>
          //   <Row gutter={16}>
          //     <Col xs={24} sm={24} md={6}>
          //       <Form.Item name="league">
          //         <Select
          //           placeholder="League"
          //           onChange={setLeague}
          //           value={league}
          //         >
          //           {leaguesList?.map((state) => (
          //             <Option key={state?.name} value={state?.name}>
          //               {state?.name}
          //             </Option>
          //           ))}
          //         </Select>
          //       </Form.Item>
          //     </Col>
          //     <Col xs={24} sm={24} md={6}>
          //       <Form.Item name="season">
          //         <Select placeholder="Season" onChange={setSeason}>
          //           {seasonList?.map((state) => (
          //             <Option key={state?.value} value={state?.value}>
          //               {state?.label}
          //             </Option>
          //           ))}
          //         </Select>
          //       </Form.Item>
          //     </Col>
          //     <Col xs={24} sm={24} md={3}>
          //       <Form.Item name="tier">
          //         <Select
          //           placeholder="Tier"
          //           onChange={setTier}
          //           value={tier}
          //           disabled={!Boolean(tiersList?.[0])}
          //         >
          //           {tiersList?.map((state) => (
          //             <Option key={state} value={state}>
          //               {Boolean(tiersList?.[0]) ? state : "No Tier"}
          //             </Option>
          //           ))}
          //         </Select>
          //       </Form.Item>
          //     </Col>
          //   </Row>
          // </Form>
          // </div>
          <></>
        )}
        <div style={isMobile ? { margin: "0 -30px" } : { margin: "0 0px" }}>
          {current === "standings" && (
            <Table
              loading={loading}
              dataSource={rankingDataSource?.[0]?.registered_teams?.map(
                (e) => ({
                  ...e,
                  ...e?.standings,
                })
              )}
              columns={standingsColumns}
              pagination={false}
              scroll={{
                y: 400,
              }}
            />
          )}
          {current === "scores" && (
            <div className={styles.container}>
              <Table
                loading={loading}
                dataSource={matchesList}
                columns={scoreColumns}
                pagination={false}
                settings={settings}
                scroll={{
                  y: 400,
                }}
              />
            </div>
          )}
          {current === "schedule" && (
            <Schedule
              season={season}
              league={league}
              tier={tier}
              settings={settings}
              leaguesList={leaguesList}
              scroll={{
                y: 400,
              }}
            />
          )}
          {current === "leaders" && (
            <Leaders
              season={season}
              leaderstats={leaderstats}
              leaderstype={leaderstype}
              detailType={detailType}
              league={league}
              settings={settings}
              tier={tier}
              // league={league}
              // tier={tier}
              // leaguesList={leaguesList}
              scroll={{
                y: 400,
              }}
            />
          )}
          {current === "teamstats" && (
            <TeamStats
              season={season}
              league={league}
              tier={tier}
              leaguesList={leaguesList}
              detailType={detailType}
              settings={settings}
              // league={league}
              // tier={tier}
              // leaguesList={leaguesList}
              scroll={{
                y: 400,
              }}
            />
          )}
          {current === "stats" && (
            <div className={styles.container}>
              <Table
                loading={loading}
                //loading={listing}
                columns={logColumns}
                scroll={{
                  y: 400,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      // setmatchstats(record);
                      // setopenmatchstats(true);
                    }, // click row
                  };
                }}
                pagination={{
                  total: count,
                  defaultPageSize: 20,
                  // onChange: (current, pageSize) => {
                  //   console.log(current, pageSize);
                  //   filters.offset = (current - 1) * pageSize;
                  //   filters.limit = pageSize;
                  //   setFilters({ ...filters })
                  // },
                  // onShowSizeChange: (current, pageSize) => {
                  //   console.log(current, pageSize);
                  //   filters.offset = (current - 1) * pageSize;
                  //   filters.limit = pageSize;
                  //   setFilters({ ...filters })
                  // }
                }}
                // scroll={{
                //   y: window.innerHeight - 300,
                //   x: window.innerWidth - 292,
                // }}
                dataSource={matches}
                //className="matches-list"
                //onChange={handleTableChange}
                // onRow={(record) => {
                //   return {
                //     onClick: (e) => {
                //       if ($(e.target).closest('.m-act').length === 0) {
                //         $(e.target).closest('tr.ant-table-row').find('.ant-table-row-expand-icon').trigger('click')
                //       }
                //     }
                //   }
                // }}
                // expandable={{
                //   rowExpandable: (record) =>
                //     (record?.home?.players?.length > 0 ||
                //       record?.away?.players?.length > 0) &&
                //     !record?.restrict_publish,
                //   //...(matchFilterApplied && { expandedRowKeys: [matchFilterApplied] }),
                //   expandedRowRender: (record, key) => (
                //     <MatchView key={key} match={record} />
                //   ),
                // }}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        //title={getModalTitle(modalMode)}
        open={openmatchstats}
        //closable={showClosable}
        footer={null}
        width={isMobile ? "100%" : "65%"}
        style={isMobile ? { maxWidth: "calc(100vw - 10px)" } : undefined}
        // style={modalMode === modalModeTypes(1)? {top: 20} : null}
        destroyOnClose
        onCancel={() => {
          setopenmatchstats(false);
          setmatchstats({});
        }}
      >
        <MatchView match={matchstats} />
      </Modal>
    </div>
  );
};
export default Stats;
