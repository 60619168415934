// eslint-disable-next-line no-unused-vars
import { Table, Grid, Tooltip, Button, Input, Space } from "antd";
import log from "../../assets/images/log.png";

import loc from "../../assets/images/loc.png";
import styles from "./stats.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import basktetball from "../../assets/images/basketball-jersey.png";
import rankicon from "../../assets/images/rank.svg";
import ApiConfig from "../../configs/ApiConfig";
import { env } from "../../configs/EnvironmentConfig";
import Utils from "../../utils";
import api from "../../configs/api";
//import Highlighter from 'react-highlight-words';

const Stat = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { useBreakpoint } = Grid;

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText('');
  // };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div className={styles.padding} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          {/* <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      console.log(value, record, "value,record", dataIndex);
      return record["first_name"]
        ?.split(" ")?.[0]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const [settings, setsettings] = useState("");
  const getaccountsettings = async () => {
    try {
      const response = await api.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      setsettings(response?.data?.[0]?.logo);
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  console.log(settings, "settingssettingssd");
  useEffect(() => {
    getaccountsettings();
  }, []);
  const scoreColumnsBasicPlayer = [
    {
      title: (
        <div className={isMobile ? styles.mobilename : styles.mobiledesk}>
          <span className={styles.ranking}>
            <img src={rankicon} alt="#" width="15px" />
          </span>{" "}
          <span>Name</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
      ...getColumnSearchProps("name"),
      //width:isMobile?'30px':'30px',
      render: (text, record, key) => (
        <div
          className={
            isMobile ? styles.mobilenamecontainer : styles.desktopnamecontainer
          }
        >
          <span className={styles.rank}>{record.rank + 1}</span>
          <span className={styles.name}>
            <Tooltip title={record?.name}>
              <img
                src={
                  Boolean(record?.logo)
                    ? `${
                        process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                          "tcbl"
                        )
                          ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
                          : "https://leagueofchamps.s3.ca-central-1.amazonaws.com"
                      }${record?.logo}`
                    : settings
                }
                height="15px"
                width="15px"
                alt="img"
              />
            </Tooltip>
          </span>
          <Tooltip
            title={
              <span>
                {record?.first_name} {record?.last_name}
              </span>
            }
          >
            {record?.first_name} {record?.last_name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <span>
          <img src={basktetball} alt="#" width="15px" />
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      fixed: isMobile ? "" : "left",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div>
          <span className={styles.statsjersey}>
            <span className={styles.hashtag}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          GP
        </span>
      ),
      dataIndex: "gp",
      fixed: isMobile ? "" : "left",
      key: "gp",
      width: isMobile ? 40 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          W
        </span>
      ),
      dataIndex: "w",
      key: "w",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          L
        </span>
      ),
      dataIndex: "l",
      key: "l",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    true
      ? {
          title: <span className={styles.tier}>Division</span>,
          dataIndex: "tier",
          key: "tier",
          width: 80,
          render: (record, stats) => (
            <div className={isMobile ? styles.mobilestatsgp : undefined}>
              {(
                props.settings?.divisions?.[props.tier] ?? props.tier
              ).toUpperCase()}
            </div>
          ),
        }
      : { width: "0px" },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          PTS
        </span>
      ),
      dataIndex: "pts",
      key: "pts",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.mobilestatsgp : undefined}>
          {parseFloat(record).toFixed(0)}
        </div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FGM
        </span>
      ),
      dataIndex: "fgm",
      width: isMobile ? 50 : 50,
      key: "fgm",
      sorter: (a, b) => a.fgm - b.fgm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.fgm).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FGA
        </span>
      ),
      dataIndex: "fga",
      width: isMobile ? 50 : 50,
      key: "fga",
      sorter: (a, b) => a.fga - b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.fga).toFixed(0)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FG%</span>,
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a.fgm / a.fga - b.fgm / b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["fga"])
              ? parseFloat((record["fgm"] / record["fga"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          2PM
        </span>
      ),
      width: isMobile ? 50 : 50,
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          2PA
        </span>
      ),
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a["2ta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2P%</span>,
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          3PM
        </span>
      ),
      width: isMobile ? 50 : 50,
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          3PA
        </span>
      ),
      dataIndex: "3pta",
      width: isMobile ? 50 : 50,
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3P%</span>,
      dataIndex: "3pta",
      width: isMobile ? 50 : 50,
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FTM
        </span>
      ),
      dataIndex: "ftm",
      key: "ftm",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.ftm).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FTA
        </span>
      ),
      dataIndex: "fta",
      width: isMobile ? 50 : 50,
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record["fta"]).toFixed(0)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FT%</span>,
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a.ftm / a.fta - b.ftm / b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["fta"])
              ? parseFloat((record["ftm"] / record["fta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          REB
        </span>
      ),
      dataIndex: "reb",
      key: "reb",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          OREB
        </span>
      ),
      dataIndex: "oreb",
      key: "oreb",
      width: isMobile ? 60 : 50,
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          DREB
        </span>
      ),
      dataIndex: "dreb",
      key: "dreb",
      width: isMobile ? 60 : 50,
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          AST
        </span>
      ),
      dataIndex: "ast",
      key: "ast",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          STL
        </span>
      ),
      dataIndex: "stl",
      key: "stl",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          BLK
        </span>
      ),
      dataIndex: "blk",
      key: "blk",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          TO
        </span>
      ),
      dataIndex: "to",
      key: "to",
      width: isMobile ? 35 : 50,
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          PF
        </span>
      ),
      dataIndex: "pf",
      key: "pf",
      width: isMobile ? 35 : 50,
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record ?? 0).toFixed(0) ?? 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          TF
        </span>
      ),
      dataIndex: "tf",
      key: "tf",
      width: isMobile ? 35 : 50,
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record ?? 0).toFixed(0) ?? 0}
          </div>
        ),
    },
  ];
  const scoreColumnsBasicPlayerAverage = [
    {
      title: (
        <div className={isMobile ? styles.mobilename : styles.mobiledesk}>
          <span className={styles.name}>
            <img src={rankicon} alt="#" width="15px" />
          </span>{" "}
          <span>Name</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
      ...getColumnSearchProps("name"),
      //width:isMobile?'30px':'30px',
      render: (text, record, key) => (
        <div
          className={
            isMobile ? styles.mobilenamecontainer : styles.desktopnamecontainer
          }
        >
          <span className={styles.rank}>{key + 1}</span>
          <span className={styles.ranking}>
            <Tooltip title={record?.name}>
              <img
                src={
                  Boolean(record?.logo)
                    ? `${
                        process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                          "tcbl"
                        )
                          ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
                          : "https://leagueofchamps.s3.ca-central-1.amazonaws.com"
                      }${record?.logo}`
                    : settings ??
                      process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? log
                    : loc
                }
                height="15px"
                width="15px"
                alt="img"
              />
            </Tooltip>
          </span>
          <Tooltip
            title={
              <span>
                {record?.first_name} {record?.last_name}
              </span>
            }
          >
            {record?.first_name} {record?.last_name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <span>
          <img src={basktetball} alt="#" width="15px" />
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div>
          <span className={styles.statsjersey}>
            <span className={styles.hashtag}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: (
        <span>
          <div
            className={isMobile ? styles.mobiletableheader : styles.tier}
          ></div>
          GP
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      fixed: isMobile ? "" : "left",
      width: isMobile ? 40 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          W
        </span>
      ),
      dataIndex: "w",
      key: "w",
      width: isMobile ? 50 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          L
        </span>
      ),
      dataIndex: "l",
      key: "l",
      width: isMobile ? 50 : 50,
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    true
      ? {
          title: <span className={styles.tier}>Division</span>,
          dataIndex: "tier",
          key: "tier",
          width: 80,
          render: (record, stats) => (
            <div className={isMobile ? styles.mob : undefined}>
              {(
                props.settings?.divisions?.[props.tier] ?? props.tier
              ).toUpperCase()}
            </div>
          ),
        }
      : { width: "0px" },
    {
      title: <span className={styles.tier}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts / a.gp - b.pts / b.gp,
      width: isMobile ? 50 : 50,
      defaultSortOrder: "ascend",
      render: (record, stats) => (
        <div className={isMobile ? styles.mobilestatsgp : undefined}>
          {parseFloat(record / stats.gp).toFixed(2)}
        </div>
      ),
    },
    {
      title: <span className={styles.tier}>FGM</span>,
      dataIndex: "fgm",
      width: isMobile ? 50 : 50,
      key: "fgm",
      sorter: (a, b) => a.fgm / a.gp - b.fgm / b.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fgm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FGA</span>,
      dataIndex: "fga",
      width: isMobile ? 50 : 50,
      key: "fga",
      sorter: (a, b) => a.fga / a.gp - b.fga / b.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fga ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FG%</span>,
      dataIndex: "fga",
      width: isMobile ? 50 : 50,
      key: "fga",
      sorter: (a, b) => a.fgm / a.fga - b.fgm / b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2PM</span>,
      width: isMobile ? 50 : 50,
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] / a.gp - b["2ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2PA</span>,
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a["2pta"] / a.gp - b["2pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2P%</span>,
      dataIndex: "2pta",
      width: isMobile ? 50 : 50,
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3PM</span>,
      width: isMobile ? 50 : 50,
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] / a.gp - b["3ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3PA</span>,
      dataIndex: "3pta",
      width: isMobile ? 50 : 50,
      key: "3pta",
      sorter: (a, b) => a["3pta"] / a.gp - b["3pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div sclassName={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3P%</span>,
      dataIndex: "3pta",
      width: isMobile ? 50 : 50,
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / a["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.ftm / a.gp - b.ftm / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.ftm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FTA</span>,
      dataIndex: "fta",
      width: isMobile ? 50 : 50,
      key: "fta",
      sorter: (a, b) => a.fta / a.gp - b.fta / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fta ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FT%</span>,
      dataIndex: "fta",
      width: isMobile ? 50 : 50,
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / a["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: isMobile ? 60 : 50,
      sorter: (a, b) => a.oreb / a.gp - b.oreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: isMobile ? 60 : 50,
      sorter: (a, b) => a.dreb / a.gp - b.dreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.reb / a.gp - b.reb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.ast / a.gp - b.ast / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.stl / a.gp - b.stl / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      width: isMobile ? 50 : 50,
      sorter: (a, b) => a.blk / a.gp - b.blk / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>TO</span>,
      dataIndex: "to",
      key: "to",
      width: isMobile ? 40 : 40,
      sorter: (a, b) => a.to / a.gp - b.to / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      width: isMobile ? 40 : 40,
      sorter: (a, b) => a.pf / a.gp - b.pf / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      width: isMobile ? 40 : 40,
      sorter: (a, b) => a.tf / a.gp - b.tf / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
  ];

  // eslint-disable-next-line no-unused-vars

  return (
    <>
      <div className={styles.container}>
        <Table
          // columns={
          //   scoreColumnsBasicPlayer
          // }
          columns={
            props.detailType === "1"
              ? scoreColumnsBasicPlayer
              : scoreColumnsBasicPlayerAverage
          }
          // columns={isMobile?scoreColumns.filter((item)=>item.key !== 'jersey_no'):scoreColumns}
          dataSource={props.leaderstats?.map((item, index) => {
            return { ...item, rank: index };
          })}
          // scroll={
          //   isMobile
          //     ? undefined
          //     : {
          //         y: 400,
          //         x: 1600,
          //       }
          // }

          scroll={{
            x: isMobile ? 800 : 1200,
            y: 400,
          }}
        />
      </div>
    </>
  );
};
export default Stat;
