import { useEffect, useState } from "react";
import { env } from "../../configs/EnvironmentConfig";
import dayjs from "dayjs";
import { Col, Form, Row, Select, Tabs, Switch, Grid } from "antd";
import ApiConfig from "../../configs/ApiConfig";
import Utils from "../../utils";
import api from "../../configs/api";

const Filters = ({
  detailType,
  setdetailType,
  current,
  leaguesList,
  setLeaguesList,
  league,
  setLeague,
  season,
  setSeason,
  seasonList,
  setSeasonList,
  tier,
  setTier,
  tiersList,
  setTiersList,
  allLeagueListasObject,
  setAllLeagueListasObject,
  leaderstype,
  setleaderstype,
}) => {
  const [form] = Form.useForm();
  //   const [leaguesList, setLeaguesList] = useState([]);
  //   const [league, setLeague] = useState("Sunday League");
  //   const [season, setSeason] = useState("");
  //   const [seasonList, setSeasonList] = useState([]);
  //   const [tier, setTier] = useState("1");
  //   const [tiersList, setTiersList] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  //const [allLeagueListasObject,setAllLeagueListasObject] = useState({});
  const { Option } = Select;
  const getLeagueNameFromAllLeagueList = (data) => {
    let leagueListName = {};
    // eslint-disable-next-line no-unused-vars
    let v1 = data?.forEach((item) => {
      leagueListName[item._id] = item.name;
    });
    return leagueListName;
  };
  console.log(seasonList, "seasonListseasonList");

  const [settings, setsettings] = useState({});
  // const c1 = {"Thursday League":"6289f76ebd1d158a386ea893",
  //   "Sunday League":"626fad6d75280628c46c18c8",
  //   "Saturday League":"626fa5a98a5c29264288c85a",
  //   "Wednesday League":"62823b02b63039209ef70d5b",
  //   "Tuesday League":"6289f769bd1d158a386ea88c",
  //   "Monday League":"6289f762bd1d158a386ea885",
  //   "Friday League":"6289f773bd1d158a386ea89a"}
  const getaccountsettings = async () => {
    try {
      const response = await api.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      const v1 = {};
      response?.data?.[0]?.divisions?.forEach((item) => {
        v1[item.divisionid] = item.label;
      });
      const v2 = {};
      response?.data?.[0]?.leagues?.forEach((item) => {
        v2[item.leagueid] = item.label;
      });
      setsettings({ leagues: { ...v2 }, divisions: { ...v1 } });
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    getaccountsettings();
  }, []);

  //function called on season change to set league list and league for league drop down
  const updateLeagueListOnSeasonChangefromFilterData = (seasonid) => {
    const index = filterdata?.findIndex((item) => item?._id === seasonid);
    const leagueslistlocal = filterdata?.[index]?.leagues?.map((item) => {
      return { value: item?.league, name: allLeagueListasObject[item?.league] };
    });
    setLeague(allLeagueListasObject[filterdata?.[index]?.leagues?.[0]?.league]);
    form.setFieldsValue({
      league: allLeagueListasObject[filterdata?.[index]?.leagues?.[0]?.league],
    });
    setLeaguesList([...leagueslistlocal]);

    // updating tier as league is changed
    const tierlistlocal = filterdata?.[index]?.leagues?.[0]?.tiers?.map(
      (item) => item.tier
    );
    setTier(
      current === "stats" || current === "scores" || current === "schedule"
        ? "All"
        : filterdata?.[index]?.leagues?.[0]?.tiers?.[0]?.tier
    );
    form.setFieldsValue({
      tier:
        current === "stats" || current === "scores" || current === "schedule"
          ? "All"
          : filterdata?.[index]?.leagues?.[0]?.tiers?.[0]?.tier,
    });
    console.log(tierlistlocal, "tierlistlocal");
    setTiersList([...tierlistlocal, "All"]); //,
  };
  //function called on initial load to set tier list and tier for tier drop down
  const updateTierListOnLeagueChangefromFilterData = (leagueid) => {
    const seasonindex = filterdata?.findIndex((item) => item?._id === season);
    const leagueindex = leaguesList?.findIndex(
      (item) => item?.name === leagueid
    );
    const leagueslistlocal = filterdata?.[seasonindex]?.leagues?.map((item) => {
      return { value: item?.league, name: allLeagueListasObject[item?.league] };
    });
    setLeague(
      allLeagueListasObject[
        filterdata?.[seasonindex]?.leagues?.[leagueindex]?.league
      ]
    );
    form.setFieldsValue({
      league:
        allLeagueListasObject[
          filterdata?.[seasonindex]?.leagues?.[leagueindex]?.league
        ],
    });
    setLeaguesList([...leagueslistlocal]);

    // updating tier as league is changed
    const tierlistlocal = filterdata?.[seasonindex]?.leagues?.[
      leagueindex
    ]?.tiers?.map((item) => item.tier);
    setTier(
      current === "stats" || current === "scores" || current === "schedule"
        ? "All"
        : filterdata?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[0]?.tier
    );
    form.setFieldsValue({
      tier:
        current === "stats" || current === "scores" || current === "schedule"
          ? "All"
          : filterdata?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[0]?.tier,
    });
    setTiersList([...tierlistlocal, "All"]);
  };
  //function called on initial load to set season list and season for season drop down
  const getSeasonListOninitialLoadfromFilterData = (data) => {
    console.log(data, "datads");
    const seasonlistlocal = data
      ?.sort((a, b) => {
        let astatus = "Completed";
        let bstatus = "Completed";
        for (let i = 0; i < a.league?.length; i++) {
          for (let j = 0; j < a.league[i]?.tiers?.length; i++) {
            if (a.league?.[i]?.tiers?.[j]?.status === "Started")
              astatus = "Started";
          }
        }
        for (let i = 0; i < b.league?.length; i++) {
          for (let j = 0; j < b.league[i]?.tiers?.length; i++) {
            if (b.league?.[i]?.tiers?.[j]?.status === "Started")
              bstatus = "Started";
          }
        }
        if (astatus === "Completed" && bstatus === "Started") return 1;
        if (
          astatus === "Completed" &&
          bstatus === "Completed" &&
          dayjs(a.created_at) < dayjs(b.created_at)
        )
          return 1;
        if (
          astatus === "Started" &&
          bstatus === "Started" &&
          dayjs(a.created_at) > dayjs(b.created_at)
        )
          return 1;
        return 0;
      })
      ?.map((item) => {
        return {
          value: item?._id,
          label: item?.name,
          is_archived: item?.is_archived,
        };
      });
    setSeason(data?.[0]?._id);
    form.setFieldsValue({ season: data?.[0]?._id });
    setSeasonList([...seasonlistlocal]);
  };
  //function called on initial load to set league list and league for league drop down
  const getLeagueListOninitialLoadfromFilterData = (data, leagueListName) => {
    const leagueslistlocal = data?.[0]?.leagues?.map((item) => {
      return { value: item?.league, name: leagueListName[item?.league] };
    });
    setLeague(leagueListName[data?.[0]?.leagues?.[0]?.league]);
    form.setFieldsValue({
      league: leagueListName[data?.[0]?.leagues?.[0]?.league],
    });
    setLeaguesList([...leagueslistlocal]);
  };
  //function called on initial load to set tier list and tier for tier drop down
  const getTierListOninitialLoadfromFilterData = (data) => {
    const tierlistlocal = data?.[0]?.leagues?.[0]?.tiers?.map(
      (item) => item.tier
    );
    setTier(
      current === "stats" || current === "scores" || current === "schedule"
        ? "All"
        : data?.[0]?.leagues?.[0]?.tiers?.[0]?.tier
    );
    form.setFieldsValue({
      tier:
        current === "stats" || current === "scores" || current === "schedule"
          ? "All"
          : data?.[0]?.leagues?.[0]?.tiers?.[0]?.tier,
    });
    setTiersList([...tierlistlocal, "All"]);
  };
  //Function called to get filter data with season>>league>>tier object
  const getFilters = async () => {
    const response = await api.get(
      `${env?.API_ENDPOINT_URL}/settings/getfilters`
    );
    setFilterData([...(response?.data?.filtersInfo ?? [])]);
    const leaguesResponse = await api.get(
      `${env?.API_ENDPOINT_URL}/settings/leagueslist`
    );
    const leagueListName = getLeagueNameFromAllLeagueList(
      leaguesResponse?.data
    );
    setAllLeagueListasObject({ ...leagueListName });
    getLeagueListOninitialLoadfromFilterData(
      response?.data?.filtersInfo ?? [],
      leagueListName
    );
    getSeasonListOninitialLoadfromFilterData(response?.data?.filtersInfo ?? []);
    getTierListOninitialLoadfromFilterData(response?.data?.filtersInfo ?? []);
  };
  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      current === "stats" ||
      current === "scores" ||
      current === "schedule" ||
      current !== "standings"
    ) {
      setTier("All");
      form.setFieldsValue({ tier: "All" });
    } else {
      setTier(tiersList?.[0]);
      form.setFieldsValue({ tier: tiersList?.[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  // const gettypename = (type) =>{

  //   let typefullname = ''
  //   switch(type){
  //     case 'blk':
  //       typefullname = "Blocks Per Game"
  //       break;
  //     case 'pts':
  //       typefullname = "Points Per Game"
  //       break;
  //     case 'ast':
  //       typefullname = "Assists Per Game"
  //       break;
  //     case 'stl':
  //       typefullname = "Steals Per Game"
  //       break;
  //     case 'reb':
  //       typefullname = "Rebounds Per Game"
  //       break;
  //     default:
  //       typefullname = "Points Per Game"
  //       break;
  //   }
  //   return typefullname?.toUpperCase()
  // }
  //const [open, setOpen] = useState(false);

  const [items, setItems] = useState([]);

  const onChange = (key) => {
    setTier(key);
  };

  const handleTier = (tierItem, divisions) => {
    return {
      key: tierItem,
      label:
        tierItem === "All"
          ? tierItem
          : (divisions?.[tierItem] ?? tierItem)?.toUpperCase(),
    };
  };

  useEffect(() => {
    setItems(
      tiersList
        ?.sort((a, b) =>
          b === "All" ? -1 : parseInt(a) > parseInt(b) ? 1 : -1
        )
        .map((state) => {
          return handleTier(state, settings?.divisions);
        })
    );
  }, [tiersList, settings?.divisions]);

  const { useBreakpoint } = Grid;
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");

  return (
    <div>
      <Form form={form}>
        {
          <Row gutter={16}>
            <Col xs={12} sm={12} md={6}>
              <Form.Item name="season">
                {console.log(seasonList, "seasonListseasonList")}
                <Select
                  placeholder="Season"
                  onChange={(e) => {
                    setSeason(e);
                    updateLeagueListOnSeasonChangefromFilterData(e);
                  }}
                  value={season}
                >
                  {seasonList
                    ?.filter((item) => !item?.is_archived)
                    ?.map((state) => (
                      <Option key={state?.value} value={state?.value}>
                        {state?.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Item name="league">
                <Select
                  placeholder="League"
                  onChange={(e) => {
                    setLeague(e);
                    updateTierListOnLeagueChangefromFilterData(e);
                  }}
                  value={league}
                >
                  {leaguesList?.map((state) => (
                    <Option key={state?.name} value={state?.name}>
                      {settings?.leagues?.[state?.value] ?? state?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={3}>
              <Form.Item name="tier">
                <Select
                  placeholder="Tier"
                  onChange={setTier}
                  value={tier}
                  disabled={!Boolean(tiersList?.[0])}
                >
                  {tiersList?.filter(item=>Boolean(item))?.sort((a,b)=>parseInt(a)>parseInt(b)?1:-1).map((state) => (
                    <Option key={state} value={state}>
                      {Boolean(tiersList?.[0]) ? (process.env.REACT_APP_DEV_API_REDIRECT_URL).includes('tcbl')?state:(state === '1'?'East':state === '2'?'West':'All')  : "No Tier"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col
              xs={24}
              sm={24}
              md={9}
              hidden={current !== "teamstats" && current !== "leaders"}
              style={{
                display:
                  current !== "teamstats" && current !== "leaders"
                    ? "none"
                    : "",
              }}
            >
              <span style={{ float: "right" }}>
                <Switch
                  checked={detailType === "2"} // true for "Average", false for "Total"
                  onChange={(checked) => setdetailType(checked ? "2" : "1")}
                  checkedChildren="Average"
                  unCheckedChildren="Total"
                  style={{
                    // width: "92px",
                    // height: "30px",
                    // borderRadius: "15px",
                    // fontSize: "12px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    marginTop: isMobile ? "-240px" : "-120px",
                  }}
                />
              </span>
            </Col>
            {/* <Col xs={24} sm={24} md={6}  hidden={current !== "leaders"}>
              <Form.Item name="type">
                <Select
                  placeholder="Type"
                  onChange={setleaderstype}
                  value={leaderstype}
                  defaultValue='pts'
                  //disabled={!Boolean(tiersList?.[0])}
                >
                  {['pts','ast','reb','stl','blk']?.map((state) => (
                    <Option key={state} value={state}>
                      {gettypename(state)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} sm={24} md={9}  hidden={current !== "leaders"}>
            <span style={{float:'right'}}>
          <Button type={detailType==='1'?'primary':undefined} style={{borderRadius:'5px 0px 0px 5px',marginRight:'-1px'}} onClick={()=>setdetailType('1')}>Detail</Button>
          <Button  type={detailType==='2'?'primary':undefined} style={{borderRadius:'0px 5px 5px 0px'}} onClick={()=>setdetailType('2')}>Basic</Button>
          </span>
          </Col> */}
          </Row>
        }
      </Form>
      <Tabs defaultActiveKey="1" onChange={onChange} items={items}></Tabs>
    </div>
  );
};
export default Filters;
